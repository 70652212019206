body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  color: red;
}

html {
  height: 100%;
}

html,
body,
#root {
  min-height: 100vh !important;
}

@media (min-width: 768px) {
  #root {
    display: flex;
    flex: 1 1 auto;
  }
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url(assets/fonts/montserrat-v14-latin_cyrillic-regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto'), local('Roboto-Regular'),
    url(assets/fonts/roboto-v20-latin_cyrillic-regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Arial';
  font-style: normal;
  font-weight: normal;
  src: local('Arial'), url(assets/fonts/ArialRegular.ttf) format('ttf');
}

@font-face {
  font-family: 'Georgia';
  font-style: normal;
  font-weight: normal;
  src: local('Georgia'), url(assets/fonts/GeorgiaRegular.ttf) format('ttf');
}

@font-face {
  font-family: 'Impact';
  font-style: normal;
  font-weight: normal;
  src: local('Impact'), url(assets/fonts/ImpactRegular.ttf) format('ttf');
}

@font-face {
  font-family: 'Tahoma';
  font-style: normal;
  font-weight: normal;
  src: local('Tahoma'), url(assets/fonts/Tahoma.ttf) format('ttf');
}

@font-face {
  font-family: 'Times New Roman';
  font-style: normal;
  font-weight: normal;
  src: local('Times New Roman'),
    url(assets/fonts/TimesNewRomanRegular.ttf) format('ttf');
}

@font-face {
  font-family: 'Verdana';
  font-style: normal;
  font-weight: normal;
  src: local('Verdana'), url(assets/fonts/Verdana.ttf) format('ttf');
}

@font-face {
  font-family: 'Helvetica';
  src: url(https://api.comfolks.com/fonts/d9174fff-293c-4d3c-9480-438cfec8ba57.woff2) format('woff2'),
    url(https://api.comfolks.com/fonts/3c6ab608-39c8-4a90-849c-c2a32ea0a949.woff) format('woff');
}

@font-face {
  font-family: 'Pragmatica';
  font-style: normal;
  font-weight: normal;
  src: local('Pragmatica'), url(assets/fonts/PRG55.otf) format('opentype');
}

#eye-dropper {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9999;
  pointer-events: none;
}

.cf_close-wrapper {
  display: none !important;
}

.inline-editor .rdw-editor-wrapper {
  padding: 0!important;
}

.inline-editor .rdw-editor-main {
  margin: 0!important;
  padding: 0!important;
  border: 0 !important;
}

.inline-editor .rdw-editor-main:focus-within {
  border: 0 !important;
}

.inline-editor .public-DraftStyleDefault-block {
  margin: 0!important;
}

.inline-editor .public-DraftStyleDefault-ltr {
  text-align: inherit!important;
  overflow: hidden;
}

.inline-editor .rdw-editor-toolbar {
  width: 600px;
  position: fixed;
  color: #000;
  bottom: 50px;
  left: 50px;
  z-index: 1000;
  background-color: #fff;
  padding: 6px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.inline-editor .rdw-dropdown-selectedtext {
  position: relative;
  height: 100%;
  padding: 0 5px;
}

.inline-editor .rdw-editor-toolbar,
.inline-editor .rdw-inline-wrapper,
.inline-editor .rdw-link-wrapper,
.inline-editor .rdw-fontsize-wrapper,
.inline-editor .rdw-fontsize-wrapper + div div div,
.inline-editor .rdw-text-align-wrapper,
.inline-editor .rdw-option-wrapper,
.inline-editor .rdw-dropdown-selectedtext,
.inline-editor .rdw-link-modal,
.inline-editor .rdw-fontfamily-wrapper {
  display: flex;
}

.inline-editor .rdw-link-modal-btn {
  margin-left: 10px;
  width: 75px;
  height: 30px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}

.inline-editor .rdw-link-decorator-wrapper {
  position: relative;
}

.inline-editor .rdw-link-decorator-icon {
  position: absolute;
  top: 0;
  right: -50%;
}

.inline-editor .rdw-link-modal-buttonsection {
  display: flex;
}

.inline-editor .rdw-link-modal-input {
  margin-top: 5px;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  height: 25px;
  margin-bottom: 15px;
  padding: 0 5px;
}
